export const religions = [
  {
    key: 1,
    name: 'Islam',
    title: 'Islam',
  },
  {
    key: 2,
    name: 'Kristen/Protestan',
    title: 'Kristen/Protestan',
  },
  {
    key: 3,
    name: 'Katholik',
    title: 'Katholik',
  },
  {
    key: 4,
    name: 'Hindu',
    title: 'Hindu',
  },
  {
    key: 5,
    name: 'Budha',
    title: 'Budha',
  },
  {
    key: 6,
    name: 'Khong Hu Chu',
    title: 'Khong Hu Chu',
  },
  {
    key: 7,
    name: 'Kepercayaan Pada Tuhan YME',
    title: 'Kepercayaan Pada Tuhan YME',
  },
  {
    key: 99,
    name: 'Lainnya',
    title: 'Lainnya',
  },
]

export const disabledList = [
  {
    key: 2,
    kode_huruf: 'A',
    name: 'Netra',
    title: 'Netra',
  },
  {
    key: 3,
    kode_huruf: 'B',
    name: 'Rungu',
    title: 'Rungu',
  },
  {
    key: 4,
    kode_huruf: 'C',
    name: 'Grahita Ringan',
    title: 'Grahita Ringan',
  },
  {
    key: 5,
    kode_huruf: 'C1',
    name: 'Grahita Sedang',
    title: 'Grahita Sedang',
  },
  {
    key: 6,
    kode_huruf: 'D',
    name: 'Daksa Ringan',
    title: 'Daksa Ringan',
  },
  {
    key: 7,
    kode_huruf: 'D1',
    name: 'Daksa Sedang',
    title: 'Daksa Sedang',
  },
  {
    key: 8,
    kode_huruf: 'E',
    name: 'Laras',
    title: 'Laras',
  },
  {
    key: 9,
    kode_huruf: 'F',
    name: 'Wicara',
    title: 'Wicara',
  },
  {
    key: 10,
    kode_huruf: 'G',
    name: 'Tuna Ganda',
    title: 'Tuna Ganda',
  },
  {
    key: 11,
    kode_huruf: 'H',
    name: 'Hiper Aktif',
    title: 'Hiper Aktif',
  },
  {
    key: 12,
    kode_huruf: 'i',
    name: 'Cerdas Istimewa',
    title: 'Cerdas Istimewa',
  },
  {
    key: 13,
    kode_huruf: 'J',
    name: 'Bakat Istimewa',
    title: 'Bakat Istimewa',
  },
  {
    key: 14,
    kode_huruf: 'K',
    name: 'Kesulitan Belajar',
    title: 'Kesulitan Belajar',
  },
  {
    key: 15,
    kode_huruf: 'N',
    name: 'Narkoba',
    title: 'Narkoba',
  },
  {
    key: 16,
    kode_huruf: 'O',
    name: 'Indigo',
    title: 'Indigo',
  },
  {
    key: 17,
    kode_huruf: 'P',
    name: 'Down Sindrome',
    title: 'Down Sindrome',
  },
  {
    key: 18,
    kode_huruf: 'Q',
    name: 'Autis',
    title: 'Autis',
  },
]

export const livedWithList = [
  {
    key: 1,
    name: 'Bersama Orang Tua',
    title: 'Bersama Orang Tua',
  },
  {
    key: 2,
    name: 'Wali',
    title: 'Wali',
  },
  {
    key: 3,
    name: 'Kos',
    title: 'Kos',
  },
  {
    key: 4,
    name: 'Asrama',
    title: 'Asrama',
  },
  {
    key: 5,
    name: 'Panti Asuhan',
    title: 'Panti Asuhan',
  },
  {
    key: 6,
    name: 'Pesantren',
    title: 'Pesantren',
  },
  {
    key: 99,
    name: 'Lainnya',
    title: 'Lainnya',
  },
]

export const transportationModeList = [
  {
    key: 1,
    name: 'Jalan Kaki',
    title: 'Jalan Kaki',
  },
  {
    key: 2,
    name: 'Kendaraan Pribadi',
    title: 'Kendaraan Pribadi',
  },
  {
    key: 3,
    name: 'Kendaraan Umum/angkot/Pete-pete',
    title: 'Kendaraan Umum/angkot/Pete-pete',
  },
  {
    key: 4,
    name: 'Jemputan Sekolah',
    title: 'Jemputan Sekolah',
  },
  {
    key: 5,
    name: 'Kereta Api',
    title: 'Kereta Api',
  },
  {
    key: 6,
    name: 'Ojek',
    title: 'Ojek',
  },
  {
    key: 7,
    name: 'Andong/Bendi/Sado/ Dokar/Delman/Beca',
    title: 'Andong/Bendi/Sado/ Dokar/Delman/Beca',
  },
  {
    key: 8,
    name: 'Perahu penyebrangan/Rakit/Getek',
    title: 'Perahu penyebrangan/Rakit/Getek',
  },
  {
    key: 99,
    name: 'Lainnya',
    title: 'Lainnya',
  },
]

export const alasanLayakPIPLists = [
  {
    key: 1,
    name: 'Pemegang PKH/KPS/KIP',
    title: 'Pemegang PKH/KPS/KIP',
  },
  {
    key: 2,
    name: 'Penerima BSM 2014',
    title: 'Penerima BSM 2014',
  },
  {
    key: 3,
    name: 'Yatim Piatu/Panti Asuhan/Panti Sosial',
    title: 'Yatim Piatu/Panti Asuhan/Panti Sosial',
  },
  {
    key: 4,
    name: 'Dampak Bencana Alam',
    title: 'Dampak Bencana Alam',
  },
  {
    key: 5,
    name: 'Pernah Drop Out',
    title: 'Pernah Drop Out',
  },
  {
    key: 6,
    name: 'Siswa Miskin/Rentan Miskin',
    title: 'Siswa Miskin/Rentan Miskin',
  },
  {
    key: 7,
    name: 'Daerah Konflik',
    title: 'Daerah Konflik',
  },
  {
    key: 8,
    name: 'Keluarga Terpidana',
    title: 'Keluarga Terpidana',
  },
  {
    key: 9,
    name: 'Kelainan Fisik',
    title: 'Kelainan Fisik',
  },
]

export const monthlyRevenueList = [
  {
    key: 1,
    name: 'Kurang Dari 500.000',
    title: 'Kurang Dari 500.000',
  },
  {
    key: 2,
    name: '500.000 - 999.999',
    title: '500.000 - 999.999',
  },
  {
    key: 3,
    name: '1 Juta - 1.999.999',
    title: '1 Juta - 1.999.999',
  },
  {
    key: 4,
    name: '2 Juta - 4.999.999',
    title: '2 Juta - 4.999.999',
  },
  {
    key: 5,
    name: '5 Juta - 20 Juta',
    title: '5 Juta - 20 Juta',
  },
  {
    key: 6,
    name: 'Lebih Dari 20 Juta',
    title: 'Lebih Dari 20 Juta',
  },
  {
    key: 7,
    name: 'Tidak Berpenghasilan',
    title: 'Tidak Berpenghasilan',
  },
]

export const educationsList = [
  {
    key: 1,
    name: 'Tidak Sekolah',
    title: 'Tidak Sekolah',
  },
  {
    key: 2,
    name: 'Putus SD',
    title: 'Putus SD',
  },
  {
    key: 3,
    name: 'SD Sederajat',
    title: 'SD Sederajat',
  },
  {
    key: 4,
    name: 'SMP Sederajat',
    title: 'SMP Sederajat',
  },
  {
    key: 5,
    name: 'SMA Sederajat',
    title: 'SMA Sederajat',
  },
  {
    key: 6,
    name: 'D1',
    title: 'D1',
  },
  {
    key: 7,
    name: 'D2',
    title: 'D2',
  },
  {
    key: 8,
    name: 'D3',
    title: 'D3',
  },
  {
    key: 9,
    name: 'D4/S1',
    title: 'D4/S1',
  },
  {
    key: 10,
    name: 'S2',
    title: 'S2',
  },
  {
    key: 11,
    name: 'S3',
    title: 'S3',
  },
]

export const worksList = [
  {
    key: 1,
    name: 'Tidak Bekerja',
    title: 'Tidak Bekerja',
  },
  {
    key: 2,
    name: 'Nelayan',
    title: 'Nelayan',
  },
  {
    key: 3,
    name: 'Petani',
    title: 'Petani',
  },
  {
    key: 4,
    name: 'Peternak',
    title: 'Peternak',
  },
  {
    key: 5,
    name: 'PNS/TNI/POLRI',
    title: 'PNS/TNI/POLRI',
  },
  {
    key: 6,
    name: 'Karyawan Swasta',
    title: 'Karyawan Swasta',
  },
  {
    key: 7,
    name: 'Pedagang Kecil',
    title: 'Pedagang Kecil',
  },
  {
    key: 8,
    name: 'Pedagang Besar',
    title: 'Pedagang Besar',
  },
  {
    key: 9,
    name: 'Wiraswasta',
    title: 'Wiraswasta',
  },
  {
    key: 10,
    name: 'Wirausaha',
    title: 'Wirausaha',
  },
  {
    key: 11,
    name: 'Buruh',
    title: 'Buruh',
  },
  {
    key: 12,
    name: 'Pensiunan',
    title: 'Pensiunan',
  },
  {
    key: 13,
    name: 'Meninggal Dunia',
    title: 'Meninggal Dunia',
  },
  {
    key: 14,
    name: 'Lain-lain',
    title: 'Lain-lain',
  },
]

export const extracurricularList = [
  {
    key: 1,
    name: 'Bahasa',
    title: 'Bahasa',
  },
  {
    key: 2,
    name: 'Karya Ilmiah Remaja/Sains KIR',
    title: 'Karya Ilmiah Remaja/Sains KIR',
  },
  {
    key: 3,
    name: 'Kerohanian',
    title: 'Kerohanian',
  },
  {
    key: 4,
    name: 'Komputer dan Teknologi',
    title: 'Komputer dan Teknologi',
  },
  {
    key: 5,
    name: 'Olahraga/Beladiri',
    title: 'Olahraga/Beladiri',
  },
  {
    key: 6,
    name: 'Otomotif/Bengkel/Bikers',
    title: 'Otomotif/Bengkel/Bikers',
  },
  {
    key: 7,
    name: 'Palang Merah Remaja(PMR)',
    title: 'Palang Merah Remaja(PMR)',
  },
  {
    key: 8,
    name: 'Paskibra',
    title: 'Paskibra',
  },
  {
    key: 9,
    name: 'Palang Keamanan Sekolah (PKS)',
    title: 'Palang Keamanan Sekolah (PKS)',
  },
  {
    key: 10,
    name: 'Pencinta Alam',
    title: 'Pencinta Alam',
  },
  {
    key: 11,
    name: 'Pramuka',
    title: 'Pramuka',
  },
  {
    key: 12,
    name: 'Seni Media, Jurnalistik, Fotografi',
    title: 'Seni Media, Jurnalistik, Fotografi',
  },
  {
    key: 13,
    name: 'Seni Musik',
    title: 'Seni Musik',
  },
  {
    key: 14,
    name: 'Seni Tari dan Peran',
    title: 'Seni Tari dan Peran',
  },
  {
    key: 15,
    name: 'Unit Kesehatan Sekolah(UKS)',
    title: 'Unit Kesehatan Sekolah(UKS)',
  },
  {
    key: 16,
    name: 'Wirausaha/Koperasi/keterampilan produktif',
    title: 'Wirausaha/Koperasi/keterampilan produktif',
  },
]
export const jenisPrestasiList = [
  {
    key: 1,
    name: 'sains',
    title: 'Sains',
  },
  {
    key: 2,
    name: 'seni',
    title: 'Seni',
  },
  {
    key: 3,
    name: 'olahraga',
    title: 'Olahraga',
  },
  {
    key: 4,
    name: 'lain-lain',
    title: 'Lain-lain',
  },
]
export const tingkatPrestasiList = [
  {
    key: 1,
    name: 'sekolah',
    title: 'Kecamatan',
  },
  {
    key: 2,
    name: 'kabupaten_kota',
    title: 'Kabupaten/Kota',
  },
  {
    key: 3,
    name: 'provinsi',
    title: 'Provinsi',
  },
  {
    key: 4,
    name: 'nasional',
    title: 'Nasional',
  },
  {
    key: 5,
    name: 'internasional',
    title: 'Internasional',
  },
]
export const jenisBeasiswaList = [
  {
    key: 1,
    name: 'anak_berprestasi',
    title: 'Anak Berprestasi',
  },
  {
    key: 2,
    name: 'anak_miskin',
    title: 'Anak Miskin',
  },
  {
    key: 3,
    name: 'pendidikan',
    title: 'Pendidikan',
  },
  {
    key: 4,
    name: 'unggulan',
    title: 'Unggulan',
  },
  {
    key: 5,
    name: 'lain-lain',
    title: 'Lain-lain',
  },
]
export const jenisPendaftaranList = [
  {
    key: 1,
    name: 'siswa_baru',
    title: 'Siswa Baru',
  },
  {
    key: 2,
    name: 'pindahan',
    title: 'Pindahan',
  },
  {
    key: 3,
    name: 'kembali_bersekolah',
    title: 'Kembali Bersekolah',
  },
]
export const jenisSekolahList = [
  {
    key: 1,
    name: 'sekolah_nasional',
    title: 'Sekolah Nasional',
  },
  {
    key: 2,
    name: 'sekolah_nasional_plus',
    title: 'Sekolah Nasional Plus',
  },
  {
    key: 3,
    name: 'sekolah_internasional',
    title: 'Sekolah Internasional',
  },
  {
    key: 4,
    name: 'madrasah',
    title: 'Madrasah',
  },
  {
    key: 5,
    name: 'sekolah_alam',
    title: 'Sekolah Alam',
  },
  {
    key: 6,
    name: 'sekolah_rumah',
    title: 'Sekolah Rumah',
  },
]
export const statusSekolahList = [
  {
    key: 1,
    name: 'negeri',
    title: 'Negeri',
  },
  {
    key: 2,
    name: 'swasta',
    title: 'Swasta',
  },
]
export const jenjangSekolahList = [
  // {
  //   key: 1,
  //   name: 'tk',
  //   title: 'TK',
  // },
  // {
  //   key: 2,
  //   name: 'kb',
  //   title: 'KB',
  // },
  // {
  //   key: 3,
  //   name: 'tpa',
  //   title: 'TPA',
  // },
  // {
  //   key: 4,
  //   name: 'sps',
  //   title: 'SPS',
  // },
  {
    key: 5,
    name: 'sd',
    title: 'SD',
  },
  {
    key: 6,
    name: 'smp',
    title: 'SMP',
  },
  {
    key: 7,
    name: 'sdlb',
    title: 'SDLB',
  },
  {
    key: 8,
    name: 'smplb',
    title: 'SMPLB',
  },
  // {
  //   key: 9,
  //   name: 'smalb',
  //   title: 'SMALB',
  // },
  // {
  //   key: 10,
  //   name: 'mi',
  //   title: 'MI',
  // },
  // {
  //   key: 11,
  //   name: 'mts',
  //   title: 'MTs',
  // },
  // {
  //   key: 12,
  //   name: 'paket_a',
  //   title: 'Paket A',
  // },
  // {
  //   key: 13,
  //   name: 'paket_b',
  //   title: 'Paket B',
  // },
  // {
  //   key: 14,
  //   name: 'sma',
  //   title: 'SMA',
  // },
  // {
  //   key: 15,
  //   name: 'smalb',
  //   title: 'SMLB',
  // },
  // {
  //   key: 16,
  //   name: 'smk',
  //   title: 'SMK',
  // },
  // {
  //   key: 17,
  //   name: 'ma',
  //   title: 'MA',
  // },
  // {
  //   key: 18,
  //   name: 'mak',
  //   title: 'MAK',
  // },
  // {
  //   key: 19,
  //   name: 'paket_c',
  //   title: 'Paket C',
  // },
  // {
  //   key: 20,
  //   name: 'akademik',
  //   title: 'Akademik',
  // },
  // {
  //   key: 21,
  //   name: 'politeknik',
  //   title: 'Politeknik',
  // },
  // {
  //   key: 22,
  //   name: 'sekolah_tinggi',
  //   title: 'Sekolah tinggi',
  // },
  // {
  //   key: 23,
  //   name: 'institut',
  //   title: 'Institut',
  // },
  // {
  //   key: 24,
  //   name: 'universitas',
  //   title: 'Universitas',
  // },
  // {
  //   key: 25,
  //   name: 'slb',
  //   title: 'SLB',
  // },
  // {
  //   key: 26,
  //   name: 'kursus',
  //   title: 'Kursus',
  // },
  // {
  //   key: 27,
  //   name: 'keaksaran',
  //   title: 'Keaksaran',
  // },
  // {
  //   key: 28,
  //   name: 'tbm',
  //   title: 'TBM',
  // },
  // {
  //   key: 29,
  //   name: 'pkbm',
  //   title: 'PKBM',
  // },
  // {
  //   key: 30,
  //   name: 'life_skill',
  //   title: 'Life Skill',
  // },
  // {
  //   key: 31,
  //   name: 'satap_tk_sd',
  //   title: 'Satap TK SD',
  // },
  {
    key: 32,
    name: 'satap_sd_smp',
    title: 'Satap SD SMP',
  },
  // {
  //   key: 33,
  //   name: 'satap_tk_sd_smp',
  //   title: 'Satap TK SD SMP',
  // },
  // {
  //   key: 34,
  //   name: 'satap_tk_sd_smp_sma',
  //   title: 'Satap TK SD SMP SMA',
  // },
  // {
  //   key: 35,
  //   name: 'ra',
  //   title: 'RA',
  // },
  {
    key: 36,
    name: 'smp_terbuka',
    title: 'SMP Terbuka',
  },
  {
    key: 37,
    name: 'smptk',
    title: 'SMPTK',
  },
  // {
  //   key: 38,
  //   name: 'smtk',
  //   title: 'SMTK',
  // },
  {
    key: 39,
    name: 'sdtk',
    title: 'SDTK',
  },
  // {
  //   key: 40,
  //   name: 'spk_pg',
  //   title: 'SPK PG',
  // },
  // {
  //   key: 41,
  //   name: 'spk_tk',
  //   title: 'SPK TK',
  // },
  {
    key: 42,
    name: 'spk_sd',
    title: 'SPK SD',
  },
  {
    key: 43,
    name: 'spk_smp',
    title: 'SPK SMP',
  },
  // {
  //   key: 44,
  //   name: 'spk_sma',
  //   title: 'SPK SMA',
  // },
  // {
  //   key: 45,
  //   name: 'pondok_peserta',
  //   title: 'Pondok Peserta',
  // },
  // {
  //   key: 46,
  //   name: 'smag_k',
  //   title: 'SMAg.K',
  // },
  // {
  //   key: 47,
  //   name: 'skb',
  //   title: 'SKB',
  // },
  // {
  //   key: 48,
  //   name: 'taman_seminari',
  //   title: 'Taman Seminari',
  // },
  // {
  //   key: 49,
  //   name: 'tklb',
  //   title: 'TKLB',
  // },
  // {
  //   key: 50,
  //   name: 'prantama_w_p',
  //   title: 'Pratama W P',
  // },
  // {
  //   key: 51,
  //   name: 'adi_w_p',
  //   title: 'Adi W P',
  // },
  // {
  //   key: 52,
  //   name: 'madyama_w_p',
  //   title: 'Madyama W P',
  // },
  // {
  //   key: 53,
  //   name: 'utama_w_p',
  //   title: 'Utama W P',
  // },
  // {
  //   key: 54,
  //   name: 'smak',
  //   title: 'SMAK',
  // },
  // {
  //   key: 55,
  //   name: 'spk_kb',
  //   title: 'SPK KB',
  // },
]
export const keluarKarenaList = [
  {
    key: 1,
    name: 'lulus',
    title: 'Lulus',
  },
  {
    key: 2,
    name: 'mutasi',
    title: 'Mutasi',
  },
  {
    key: 3,
    name: 'dikeluarkan',
    title: 'Dikeluarkan',
  },
  {
    key: 4,
    name: 'mengundurkan_diri',
    title: 'Mengundurkan Diri',
  },
  {
    key: 5,
    name: 'putus_sekolah',
    title: 'Putus Sekolah',
  },
  {
    key: 6,
    name: 'wafat',
    title: 'Wafat',
  },
  {
    key: 7,
    name: 'hilang',
    title: 'Hilang',
  },
  {
    key: 8,
    name: 'lainnya',
    title: 'Lainnya',
  },
]
export const jarakTempuhList = [
  {
    key: 1,
    title: 'Kurang Dari 1 km',
  },
  {
    key: 2,
    title: '1 km - 4.9 km',
  },
  {
    key: 3,
    title: '5 km - 9.9 km',
  },
  {
    key: 4,
    title: '10 km - 14.9 km',
  },
  {
    key: 5,
    title: '15 km - 19.9 km',
  },
  {
    key: 6,
    title: 'Lebih Dari 20 km',
  },
]
export const waktuTempuhList = [
  {
    key: 1,
    title: 'Kurang Dari 5 mnt',
  },
  {
    key: 2,
    title: '5 mnt - 14 mnt',
  },
  {
    key: 3,
    title: '15 mnt - 29 mnt',
  },
  {
    key: 4,
    title: '30 mnt - 44 mnt',
  },
  {
    key: 5,
    title: '45 mnt - 59 mnt',
  },
  {
    key: 6,
    title: 'Lebih Dari 1 jam',
  },
]
